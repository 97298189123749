<template>
  <div>
    <el-tree
      :data="treeData"
      show-checkbox
      node-key="id"
      :default-checked-keys="checkedKeys"
      :props="defaultProps"
      @check="handleCheckChange"
    >
    </el-tree>
    <div class="btn_box">
      <el-button class="btn" type="primary" @click="submit">确定</el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    treeData: Array,
    paramsObj: Object,
    handleClose: Function,
    getRoleUpdateRP: Function,
  },
  name: "BindDept",
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      checkedKeys: [],
      rePermIds: [],
    };
  },
  created() {
    this.getRoleQueryRP();
  },
  methods: {
    async getRoleQueryRP() {
      const { data } = await this.$api.getRoleQueryRP({
        id: this.paramsObj.id,
      });
      this.checkedKeys = data;
    },
    submit() {
      let ids = JSON.stringify(this.rePermIds);
      this.getRoleUpdateRP({
        id: this.paramsObj.id,
        rePermIds: ids,
      });
    },
    // 权限tree控件方法
    handleCheckChange(data, checked, indeterminate) {
      if (checked.checkedKeys.length === 0) {
        this.rePermIds = "";
        return;
      }
      if (checked.halfCheckedKeys.length > 0) {
        this.rePermIds = checked.checkedKeys.concat(checked.halfCheckedKeys);
      } else {
        this.rePermIds = checked.checkedKeys;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-tree {
  .el-tree-node__content {
    align-items: baseline !important;
    .el-checkbox__inner {
      width: 18px !important;
      height: 18px !important;
      &::after {
        top: 2px !important;
        left: 5px !important;
        border-width: 2px !important;
      }
    }
    .el-tree-node__label {
      font-size: 16px;
    }
  }
}
.btn_box {
  margin-top: 20px;
  text-align: right;
}
</style>
